<template>
  <section>
    <section>
      <pm-Breadcrumb :home="home" :model="items_bread" />
      <br>
      <div class="form-elements">
          <div class="row">
              <div class="col-md-9">
                <h2 class="titulo-links-escolha">Responder a pergunta do formulário</h2>
              </div>
              <div class="col-md-3" >
                <pm-Button type="button" style="float: right;margin-top: 5px;"
                  @click="trocarTurma()" label="Trocar de Turma"
                  class="p-button-danger" icon="pi pi-angle-left" iconPos="left"
                ></pm-Button>
              </div>
          </div>
      </div>
      <div class="pm-card">
        <h3 style="margin-top:15px;font-weight:400;;margin-bottom: 20px;">
          Pergunta: <u>{{perguntaASerFeita.pergunta}}</u>
        </h3>

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="th_per1" style="width: 15%">NOME DO ALUNO</th>
                <th class="th_per1 th_pn_aluno">RESPOSTA</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) of this.alunosDaTurma" :key="index">
                <td style="text-align: center; color:rgb(17 18 18); font-weight:normal">
                  {{ item.nome }}
                </td>
                <td style="text-align: center; color:rgb(17 18 18); font-weight:normal">
                  <div class="col-md-6" v-if="perguntaASerFeita.multipla_escolha == 1">
                      <fieldset :disabled="respondido == 1">
                          <va-radio
                              option="option1"
                              label="Sim"
                              v-model="item.resposta.resposta_objetiva"
                          />
                          <va-radio
                              option="option2"
                              label="Não"
                              v-model="item.resposta.resposta_objetiva"
                          />
                      </fieldset>
                  </div>
                  <div class="col-md-6" v-else >
                      <textarea  class="form-control" rows="4" v-model="item.resposta.resposta_escolha" :disabled="respondido == 1"></textarea>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class='col-md-12'>
            <button class="btn btn-primary" v-if="respondido == 0" @click="enviarRespostas">Salvar respostas</button>
            <va-button style="margin-left:8px;" class="btn btn-danger" color="danger" @click="this.$router.push({name: 'crecheFormulario'});">Voltar</va-button>
          </div>
        </div>
      </div>

    </section>
  </section>
</template>


<script>
import { defineComponent } from 'vue'
import { Turma } from "@/class/turma";
import { Pergunta } from "@/class/pergunta";
import { Creche } from "@/class/creche";

export default defineComponent({
  props: {
    id:{
      required: true
    },
    turma_id:{
      required: true
    },
    professor_id:{
      required: true
    },
    turma_disciplina_id:{
      required: true
    },
    segmento:{
      required: true
    },
    dataSelecionada:{
      required:true,
    },
    escola_id:{
      required:true,
    },
    serie_id:{
      required:true,
    },
    turno:{
      required:true,
    },
    pergunta_id:{
      required:true,
    },
    respondido:{
      required:true,
    }
  },
  data () {
    const columns = [
      {key: 'nome', label:'Nome do aluno', sortable: true },
      {key: 'resposta', label:'Resposta', sortable: true },
    ]
    return {
      home: {icon: 'pi pi-home', to: '/ambiente-professor/turmas-professor'},
      items_bread: [
        {label: ''},
      ],
      st_id: null,
      st_turma_id: null,
      st_professor_id: null,
      st_turma_disciplina_id:null,
      st_segmento:null,
      st_dataSelecionada:null,
      st_escola_id:null,
      st_serie_id:null,
      st_turno:null,
      st_pergunta_id:null,
      perguntaASerFeita:[],
      alunosDaTurma:[],
      turma_dados:[],
      filter: '',
      columns,
      perPage: 20,
      currentPage: 1,
    }
  },
  computed: {
    pages () {
      return (this.perPage && this.perPage !== '0')
          ? Math.ceil(this.alunosDaTurma.length / this.perPage)
          : this.alunosDaTurma.length
    },
  },
  methods:{
    async buscarPergunta() {
        let data = await Pergunta.perguntaCrecheUmItem(this.pergunta_id);
        this.perguntaASerFeita = data.data;
    },
    async buscarAlunosTurma() {
        let data = await Turma.obtemAlunosTurma(this.st_turma_id);
        let data1 = "";
        this.alunosDaTurma = [];
        for (const el of data.data) {
            let novo = {
                nome: el.nome_completo,
                aluno_id: el.id,
                turma_id: this.st_turma_id,
                perguntas_creche_id: this.pergunta_id,
                resposta: {
                    resposta_escolha:'',
                    resposta_objetiva:'',
                },
                resposta_objetiva:'',
                resposta_escolha:'',
            }
            this.alunosDaTurma.push(novo);
        }
        if (this.respondido == 1) {
          data1 = await Creche.respostasCrecheShowAll(this.pergunta_id);
          for (var i = 0; i < this.alunosDaTurma.length; i++) {
            let obj = data1.data.find(o => o.aluno_id == data1.data[i].aluno_id);
            if (obj.resposta_objetiva != -1) {
              this.alunosDaTurma[i].resposta.resposta_objetiva = (obj.resposta_objetiva == 1 ? "option1" : "option2");
            }
            else if (obj.resposta_escolha != -1) {
              this.alunosDaTurma[i].resposta.resposta_escolha = obj.resposta_escolha;
            }
          }
        }
    },
    async enviarRespostas() {
      try {
        for (const el of this.alunosDaTurma) {
          if (el.resposta.resposta_escolha == '' && el.resposta.resposta_objetiva == '') {
            this.$vaToast.init({
              message: "Coloque resposta para todos os alunos da turma!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              color:'warning',
              duration: 3000,
              fullWidth: false,
            });
            return false;
          }
          if (el.resposta.resposta_objetiva != '') {
            el.resposta_objetiva = (el.resposta.resposta_objetiva == "option1" ? 1 : 0);
            el.resposta_escolha = "-1";
          }
          else if (el.resposta.resposta_escolha != '') {
            el.resposta_escolha = el.resposta.resposta_escolha;
            el.resposta_objetiva = -1;
          }
        }
        let data = await Creche.cadastrarRespostas(this.alunosDaTurma);
        this.$vaToast.init({
          message: "Resposta enviada com sucesso!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'success',
          duration: 3000,
          fullWidth: false,
        });
        this.$router.push({ name: "crecheFormulario" });
      }
      catch(e) {
        this.$vaToast.init({
          message: "Problema ao enviar a resposta",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'danger',
          duration: 3000,
          fullWidth: false,
        });
      }
    },

    trocarTurma(){
      this.$router.push({ name: "turmas-Professor" });
    },


  },
  async beforeMount() {
    if(this.id == null ||  this.turma_id == null){
      this.st_id = sessionStorage.getItem("Professor_id");
      this.st_turma_id = sessionStorage.getItem("Professor_turma_id");
      this.st_professor_id = sessionStorage.getItem("Professor_professor_id");
      this.st_turma_disciplina_id = sessionStorage.getItem("Professor_turma_disciplina_id");
      this.st_segmento = sessionStorage.getItem("Professor_segmento");
      this.st_escola_id = sessionStorage.getItem("Professor_escola_id");
      this.st_serie_id = sessionStorage.getItem("Professor_serie_id");
      this.st_turno = sessionStorage.getItem("Professor_turno");
      this.st_dataSelecionada = sessionStorage.getItem("Professor_dataSelecionada");

    }else{
      this.st_id = this.id;
      this.st_turma_id= this.turma_id;
      this.st_professor_id = this.professor_id;
      this.st_turma_disciplina_id =this.turma_disciplina_id;
      this.st_segmento =this.segmento;
      this.st_escola_id =this.escola_id;
      this.st_serie_id =this.serie_id;
      this.st_turno =this.turno;
      this.st_dataSelecionada = this.dataSelecionada;
    }
    let turma = {
        turma_id: this.st_turma_id,
        etapa_id: this.st_segmento,
        periodo_id: this.st_serie_id,
        escola_id: this.st_escola_id,
    }
    let resp_turma = await Turma.aturma(turma);
    let  tt = [
        {
          label: resp_turma.data[0].escola
        },
        {
          label: resp_turma.data[0].etapa
        },
        {
          label: resp_turma.data[0].periodo
        },
        {
          label: resp_turma.data[0].turma
        },
        {
          label: resp_turma.data[0].turno
        },
    ];

    this.items_bread = tt;
    this.turma_dados = resp_turma.data;
    await this.buscarPergunta();
    await this.buscarAlunosTurma();
  }
})
</script>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .cabecario-turma{
    background-color:#2fc982;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }
  @media(max-width:500px){
    .titulo-links-escolha {
      font-size: 24px;
    }
  }
</style>

<style scoped>
  .th_per1{
    background-color: #f8f9fa !important;
    color: #495057 !important;
    border-right: 1px solid #e9ecef !important;
    border-bottom: 2px solid #e9ecef !important;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
  }
  .th_pn_aluno{
    width:15%;
  }
  @media(max-width:720px){
    .th_pn_aluno{
      width:90%;
    }
  }

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 25px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

</style>
